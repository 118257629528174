@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.max-height-wrapper {
  height: 100%;
  max-height: calc(100vh - (190px));
}

@layer base {
  .primary-bg {
    @apply bg-white;
  }

  .nav-bg {
    @apply bg-gray-50;
  }

  .dark-bg {
    @apply dark:bg-gray-800;
  }

  .container-dark-bg {
    @apply dark:bg-gray-900;
  }

  .primary-border-color {
    @apply border-gray-200
  }

  .dark-border {
    @apply dark:border-gray-700;
  }

  .dark-primary-text {
    @apply dark:text-white
  }

  .nav-btn {
    @apply rounded-lg p-2.5 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-700
  }
}
