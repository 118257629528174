/* For Chrome, Edge, and Safari */
.scrollbars::-webkit-scrollbar {
    width: 7px !important;
    height: 7px !important;;
}

.scrollbars::-webkit-scrollbar-track {
    background: hsl(217.5,17.4%,94%);
    /* background: hsl(0,0%,94.5%); */
    /* background: hsl(0, 0%, 95%) */
}

.scrollbars::-webkit-scrollbar-thumb {
    /* background: hsl(0,0%,75.7%); */
    background: hsl(0,0%,68.6%);
}

.scrollbars::-webkit-scrollbar-thumb:hover {
    background:hsl(0,0%,64.5%);
    cursor: grabbing;
}

/* For Firefox */
@-moz-document url-prefix() {
    .scrollbars {
        scrollbar-color: hsl(0,0%,68.6%) hsl(0,0%,94.5%);
        scrollbar-width: thin;
    }
}
/* For Internet Explorer and older versions of Edge */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .scrollbars {
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .scrollbars {
        scrollbar-face-color: hsl(0,0%,68.6%);
        scrollbar-track-color: hsl(0,0%,94.5%);
    }
}


/* 
- override styles in <ReactFlow/>
*/

.react-flow__controls {
    background-color: white !important;
}

/* remove react-flow water-mark */
.react-flow__panel.right{
    display: none !important;
}