.touchdevice-flow .react-flow__handle {
    width: 13px;
    height: 13px;
    border-radius: 3px;
    @apply bg-white rounded-2xl border-gray-400;
}

.touchdevice-flow .react-flow__handle.connecting {
    animation: bounce 1600ms infinite ease-out;
}

@keyframes bounce {
    0% {
        transform: translate(0, -50%) scale(1);
    }
    50% {
        transform: translate(0, -50%) scale(1.1);
    }
}